






import { Component, Prop, Emit } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import G6 from '@antv/g6'
import linkImage from '@/assets/link.png'

@Component({
  name: 'LinkPicture',
})
export default class LinkPicture extends VueBase {
  @Prop() data!: any

  @Emit('openRight')
  private openRight(flag: any, node: any) {
    return [flag, node]
  }

  @Emit('toLink')
  private toLink(node: any) {
    return node
  }

  private async run(height: number, width: number) {
    const offsetDiff = 10
    const multiEdgeType = 'quadratic'
    const singleEdgeType = 'line'
    const loopEdgeType = 'loop'
    G6.Util.processParallelEdges(
      this.data.edges,
      offsetDiff,
      multiEdgeType,
      singleEdgeType,
      loopEdgeType
    )
    const graphT = new G6.Graph({
      container: 'mountNodeT',
      linkCenter: true,
      height,
      width,
      modes: {
        default: ['drag-canvas', 'zoom-canvas'],
      },
      animate: false,
      layout: {
        type: 'gForce',
        preventOverlap: true,
        // width: width,
        // height: height,
        // center: [width/2, (height-200)/2],
        // kr: 10,
        // ks: 1,
        linkDistance: 100,
        // animate: false,
        nodeSize: 20,
        // nodeStrength: function (e: any) {
        //   return e.type === 'rect' ? 8000 : 4000
        // },
      },
      defaultNode: {
        size: 30,
      },
      defaultEdge: {
        style: {
          endArrow: {
            path: G6.Arrow.triangle(6, 6, 18), // 使用内置箭头路径函数，参数为箭头的 宽度、长度、偏移量（默认为 0，与 d 对应）
            d: 18,
          },
        },
      },
    })

    graphT.node((node: any) => {
      let { vul_levels } = node
      vul_levels.forEach((item: any) => {
        switch (item.level) {
          case 1:
            item.fillColor = '#FBE9E9'
            item.borderColor = '#EF4444'
            item.shadowColor = '#FEE2E2'
            break
          case 2:
            item.fillColor = '#FEF5E7'
            item.borderColor = '#F59E0B'
            item.shadowColor = '#FEF3C7'
            break
          case 3:
            item.fillColor = '#E7F6FD'
            item.borderColor = '#0EA5E9'
            item.shadowColor = '#E0F2FE'
            break
          case 5:
            item.fillColor = '#EFF1F3'
            item.borderColor = '#64748B'
            item.shadowColor = '#E2E8F0'
            break
          default:
            item.fillColor = '#E9EFFD'
            item.borderColor = '#2563EB'
            item.shadowColor = '#DBEAFE'
            break
        }
      })

      let height = vul_levels.find((item: any) => item.level == 1)
      height.size = String(height.total).length
      node.height = height

      let med = vul_levels.find((item: any) => item.level == 2)
      med.size = String(med.total).length
      node.med = med

      let low = vul_levels.find((item: any) => item.level == 3)
      low.size = String(low.total).length
      node.low = low

      let info = vul_levels.find((item: any) => item.level == 5)
      info.size = String(info.total).length
      node.info = info

      node.target_vul_level = this.findMaxTotalObject(vul_levels)

      return {
        type: 'nodeName',
      }
    })

    G6.registerNode('nodeName', {
      draw(cfg: any, group: any) {
        const {
          id,
          label,
          root,
          vul_levels,
          target_vul_level,
          height,
          med,
          low,
          info,
        } = cfg
        // 创建一个圆形节点
        group.addShape('circle', {
          attrs: {
            r: 10,
            fill: target_vul_level.shadowColor,
          },
          name: 'bg',
        })

        const node = group.addShape('circle', {
          attrs: {
            r: 15,
            fill: target_vul_level.fillColor,
            stroke: target_vul_level.borderColor,
            lineWidth: 4,
            cursor: 'pointer',
          },
          name: 'node',
        })

        group.addShape('text', {
          attrs: {
            text: label,
            x: 0, // 标签的位置
            y: 26, // 标签在节点中心
            textAlign: 'center',
            textBaseline: 'top',
            cursor: 'pointer',
            fill: '#111827', // 标签的颜色
            fontSize: 12, // 标签的字体大小
          },
          name: 'label',
        })
        // 70 35
        let all = (height.size + med.size + low.size + info.size + 3) * 7

        let heightX = -(all / 2)
        let medX = heightX + (height.size + 1) * 7
        let lowX = medX + (med.size + 1) * 7
        let infoX = lowX + (low.size + 1) * 7
        group.addShape('text', {
          attrs: {
            text: height.total,
            x: heightX, // 标签的位置
            y: -30, // 标签在节点中心
            textAlign: 'start',
            textBaseline: 'middle',
            cursor: 'pointer',
            fill: '#EF4444', // 标签的颜色
            fontSize: 12, // 标签的字体大小
            opacity: 0,
            fontWeight: 500,
          },
          name: 'height',
        })
        group.addShape('text', {
          attrs: {
            text: med.total,
            x: medX, // 标签的位置
            y: -30, // 标签在节点中心
            textAlign: 'start',
            textBaseline: 'middle',
            cursor: 'pointer',
            fill: '#F59E0B', // 标签的颜色
            fontSize: 12, // 标签的字体大小
            opacity: 0,
            fontWeight: 500,
          },
          name: 'med',
        })
        group.addShape('text', {
          attrs: {
            text: low.total,
            x: lowX, // 标签的位置
            y: -30, // 标签在节点中心
            textAlign: 'start',
            textBaseline: 'middle',
            cursor: 'pointer',
            fill: '#0EA5E9', // 标签的颜色
            fontSize: 12, // 标签的字体大小
            opacity: 0,
            fontWeight: 500,
          },
          name: 'low',
        })
        group.addShape('text', {
          attrs: {
            text: info.total,
            x: infoX, // 标签的位置
            y: -30, // 标签在节点中心
            textAlign: 'start',
            textBaseline: 'middle',
            cursor: 'pointer',
            fill: '#64748B', // 标签的颜色
            fontSize: 12, // 标签的字体大小
            opacity: 0,
            fontWeight: 500,
          },
          name: 'info',
        })

        if (cfg.is_first_node) {
          group.addShape('image', {
            attrs: {
              x: 5,
              y: -20,
              width: 20,
              height: 20,
              img: linkImage,
            },
            name: 'to-link',
          })
        }

        return node // 返回节点作为 keyShape
      },
      setState(name: any, value: any, node: any) {
        const group = node.getContainer()
        const shapes = group.get('children') // 顺序根据 draw 时确定
        if (name === 'labelHover') {
          if (value) {
            shapes[2].attr('fill', '#2563EB')
          } else {
            shapes[2].attr('fill', '#111827')
          }
        } else if (name === 'nodeHover') {
          if (value) {
            shapes[0].attr('r', '24')
            shapes[1].attr('r', '16')
            shapes[4].attr('opacity', 1)
            shapes[5].attr('opacity', 1)
            shapes[6].attr('opacity', 1)
            shapes[3].attr('opacity', 1)
          } else {
            shapes[0].attr('r', '10')
            shapes[1].attr('r', '15')
            shapes[4].attr('opacity', 0)
            shapes[5].attr('opacity', 0)
            shapes[6].attr('opacity', 0)
            shapes[3].attr('opacity', 0)
          }
        }
      },
    })

    graphT.edge((node: any) => {
      if (node.type == 'loop') {
        return {
          style: {
            lineWidth: 2,
            endArrow: {
              path: G6.Arrow.triangle(6, 6, 0), // 使用内置箭头路径函数，参数为箭头的 宽度、长度、偏移量（默认为 0，与 d 对应）
              d: 0,
            },
          },
        }
      }
      return {
        style: {
          // stroke: this.edgeColor[target],
          lineWidth: 2,
          // endArrow: {
          //   fill: this.edgeColor[target]
          // },
        },
      }
    })

    graphT.on('node:click', (e: any) => {
      let name = e.target.get('name')
      if (name === 'label') {
        this.openRight(true, e.item.get('model'))
      }
    })
    graphT.on('click', (e: any) => {
      if (e.target.get('name') === 'label') return
      this.openRight(false, null)
    })
    graphT.on('node:mouseenter', (e: any) => {
      const model = e.item.get('model')
      let name = e.target.get('name')
      if (name === 'label') {
        graphT.setItemState(e.item, 'labelHover', true)
      } else if (name === 'node') {
        graphT.setItemState(e.item, 'nodeHover', true)
      }
    })
    graphT.on('node:mouseleave', (e: any) => {
      graphT.setItemState(e.item, 'labelHover', false)
      graphT.setItemState(e.item, 'nodeHover', false)
    })

    graphT.on('node:click', (e: any) => {
      if (e.target.get('name') === 'to-link') {
        this.toLink(e.item.get('model'))
      }
    })

    // graphT.on('node:dragstart', function (e) {
    //   const forceLayout = graphT.get('layoutController').layoutMethods[0];
    //   forceLayout.stop()
    // });
    // graphT.on('node:drag', (e) => {
    //   this.refreshDragedNodePosition(e);
    //   graphT.layout()
    // });

    graphT.data(this.data) // 读取 Step 2 中的数据源到图上
    // graphT.fitCenter()
    graphT.render() // 渲染图
  }

  findMaxTotalObject(arr: any) {
    arr = this.sortByLevel(arr)
    let maxTotalObject = arr[0]
    for (let i = 1; i < arr.length; i++) {
      if (arr[i].total > maxTotalObject.total) {
        maxTotalObject = arr[i]
      }
    }
    if (maxTotalObject.total == 0) {
      maxTotalObject.level = -1
    }
    return maxTotalObject
  }
  sortByLevel(arr: any) {
    // 小 大
    return arr.sort((a: any, b: any) => a.level - b.level)
  }

  refreshDragedNodePosition(e: any) {
    const model = e.item.get('model')
    model.fx = e.x
    model.fy = e.y
    model.x = e.x
    model.y = e.y
  }

  mounted() {
    var clientHeight = document.body.clientHeight
    var clientWidth = document.body.clientWidth
    this.run(clientHeight - 160, clientWidth - 32)
  }
}
